<template>
  <div class="buy_fiat">
    <div class="info_box">
      <div class="info_r1_box">
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text11") }}</div>
          <div class="info_right">
            {{ num_format(info.currency_amount) }} {{ info.currency }}
          </div>
        </div>
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text12") }}</div>
          <div class="info_right">{{ num_format(info.hicoin) }} Hicoin</div>
        </div>
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text13") }}</div>
          <div class="info_right">
            {{ num_format(toFixedNumber(info.rate)) }} {{ info.currency }}/Hicoin
          </div>
        </div>
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text95") }}</div>
          <div class="info_right">
            {{ num_format(info.user_fee) }} Hicoin
          </div>
        </div>
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text14") }}</div>
          <div class="info_right info_id">{{ info.order_id }}</div>
        </div>
      </div>

      <div class="info_r1_box" v-if="info.currency == 'USDT'">
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text15") }}</div>
          <div class="info_right">
            {{ subStr(info.payee_addr) }}
            <i
              @click="copy_api(info.payee_addr)"
              class="el-icon-document-copy copy_icon"
            ></i>
          </div>
        </div>
      </div>

      <div class="info_r1_box" v-else>
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text16") }}</div>
          <div class="info_right">
            {{ info.payee_country }}
            <i
              @click="copy_api(info.payee_country)"
              class="el-icon-document-copy copy_icon"
            ></i>
          </div>
        </div>
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text17") }}</div>
          <div class="info_right">
            {{ info.payee_bank_name }}
            <i
              @click="copy_api(info.payee_bank_name)"
              class="el-icon-document-copy copy_icon"
            ></i>
          </div>
        </div>
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text89") }}</div>
          <div class="info_right">
            {{ info.payee_last_name }}
            <i
              @click="copy_api(info.payee_last_name)"
              class="el-icon-document-copy copy_icon"
            ></i>
          </div>
        </div>
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text90") }}</div>
          <div class="info_right">
            {{ info.payee_account_name }}
            <i
              @click="copy_api(info.payee_account_name)"
              class="el-icon-document-copy copy_icon"
            ></i>
          </div>
        </div>
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text19") }}</div>
          <div class="info_right info_id">
            {{ info.payee_card_num }}
            <i
              @click="copy_api(info.payee_card_num)"
              class="el-icon-document-copy copy_icon"
            ></i>
          </div>
        </div>
        <div class="info_r1_box_1" v-if="info.payee_swift_code">
          <div class="info_left">{{ $t("main.text20") }}</div>
          <div class="info_right info_id">
            {{ info.payee_swift_code }}
            <i
              v-if="info.payee_swift_code"
              @click="copy_api(info.payee_swift_code)"
              class="el-icon-document-copy copy_icon"
            ></i>
          </div>
        </div>
      </div>

      <div
        class="info_r1_box pz_box"
        v-if="info.status != 2 && info.payment_voucher"
      >
        <div class="info_r1_box_1">
          <div class="info_left">{{ $t("main.text21") }}</div>
          <div class="info_right">
            <el-upload
              v-if="info.status == 3"
              class="avatar-uploader"
              ref="upload-file2"
              accept=".jpg,.jpeg,.png,.JPG,.JPEG"
              action="cover"
              :show-file-list="false"
              :http-request="ossUpload_assistant"
            >
              <img
                :src="url + info.payment_voucher"
                alt=""
                style="height: 3rem; width: 3rem"
              />
            </el-upload>

            <el-image
              v-else
              style="height: 3rem; width: 3rem"
              :src="url + info.payment_voucher"
              :preview-src-list="[url + info.payment_voucher]"
            >
            </el-image>
          </div>
        </div>
      </div>

      <div class="info_r1_box_1" v-if="info.appeal_type != 0">
        <div class="info_left">{{ $t("main.text3") }}</div>
        <div class="info_right">
          <span v-if="info.appeal_type == 1">{{ $t("main.text4") }}</span>
          <span v-else-if="info.appeal_type == 2">{{ $t("main.text5") }}</span>
          <span v-else-if="info.appeal_type == 3">{{ $t("main.text6") }}</span>
          <span v-else-if="info.appeal_type == 4">{{ $t("main.text7") }}</span>
          <span v-else-if="info.appeal_type == 5">
            {{ info.appeal_reason }}
          </span>
        </div>
      </div>
    </div>

    <div class="info_footer">
      <div
        class="f_btn1"
        @click="cancel_dlg = true"
        v-if="
          (info.type == 1 && info.status == 2) ||
          (info.status == 1 && info.type == 2)
        "
      >
        {{ $t("main.text22") }}
      </div>
      <div
        class="f_btn2"
        v-if="info.status == 2 && info.payment_voucher == '' && info.type == 1"
      >
      <!-- accept=".jpg,.jpeg,.png,.JPG,.JPEG" -->
      <!-- accept="image/*" -->
        <el-upload
          class="avatar-uploader"
          ref="upload-file2"
          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
          action="cover"
          :show-file-list="false"
          :http-request="ossUpload_assistant"
        >
          {{ $t("main.text23") }}
        </el-upload>
      </div>
      <div
        :class="info.type == 1 ? 'f_btn2' : 'f_btn3'"
        @click="appeal()"
        v-if="
          (info.status == 3 && info.type == 1) ||
          (info.status == 2 && info.type == 2) ||
          (info.status == 3 && info.type == 2)
        "
      >
        {{ $t("main.text24") }}
      </div>

      <div
        class="f_btn1"
        @click="cancelAppeal_dlg = true"
        v-if="info.status == 4"
      >
        {{ $t("main.text25") }}
      </div>

      <div
        class="f_btn2"
        v-if="info.status == 3 && info.type == 2"
        @click="over_dlg = true"
      >
        {{ $t("main.text26") }}
      </div>
    </div>

    <van-dialog
      v-model="cancel_dlg"
      showConfirmButton
      show-cancel-button
      @cancel="cancel_dlg = false"
      @confirm="cancel_dlg_confirm"
      :confirmButtonText="$t('main.text45')"
      confirm-button-color="#2982D8"
      :cancelButtonText="$t('main.text46')"
    >
      <div class="v_dlg_main">{{ $t("main.text27") }}</div>
    </van-dialog>

    <van-dialog
      v-model="cancelAppeal_dlg"
      showConfirmButton
      show-cancel-button
      @cancel="cancelAppeal_dlg = false"
      @confirm="cancelAppeal_dlg_confirm"
      :confirmButtonText="$t('main.text45')"
      confirm-button-color="#2982D8"
      :cancelButtonText="$t('main.text46')"
    >
      <div class="v_dlg_main">{{ $t("main.text28") }}</div>
    </van-dialog>

    <van-dialog
      v-model="over_dlg"
      showConfirmButton
      show-cancel-button
      @cancel="over_dlg = false"
      @confirm="over_dlg_confirm"
      :confirmButtonText="$t('main.text45')"
      confirm-button-color="#2982D8"
      :cancelButtonText="$t('main.text46')"
    >
      <div class="v_dlg_main">{{ $t("main.text29") }}</div>
    </van-dialog>
  </div>
</template>

<script>
import {
  orders_get,
  cancel,
  getFSAuth,
  orders_upload,
  cancelAppeal,
  complete,
} from "../../../api/api";
import file_fun from "../../../utils/updata_file.js";
import BMF from "browser-md5-file";
import { first_up_data_url } from "../../../config/endpoints.js";
import BigNumber from "bignumber.js";

export default {
  filters: {},
  computed: {
    order_id() {
      return this.$route.query.order_id;
    },
    userid() {
      return sessionStorage.getItem("hichat_app_userid");
    },
  },
  data() {
    return {
      cancel_dlg: false,
      cancelAppeal_dlg: false,
      over_dlg: false,
      fileList: [],
      info: {},
      token: "",
      payment_voucher: "",
      url: first_up_data_url,
    };
  },
  beforeMount() {},
  async mounted() {
    this.getList();
    await this.S3Auth();
  },
  methods: {
    toFixedNumber(v) {
      if (this.info.type)
        if (this.info.type == 1) {
          return new BigNumber(v).toNumber().toFixed(2, 0);
        } else {
          return new BigNumber(v).toNumber().toFixed(2, 1);
        }
    },
    over_dlg_confirm() {
      complete({
        order_id: this.order_id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.msg("success", this.$t("main.text10"));
          this.getList();
          this.over_dlg = false;
        } else {
          this.msg("error", res.data.msg);
        }
      });
    },
    cancelAppeal_dlg_confirm() {
      cancelAppeal({
        order_id: this.order_id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.msg("success", this.$t("main.text10"));
          this.getList();
          this.cancelAppeal_dlg = false;
        } else {
          this.msg("error", res.data.msg);
        }
      });
    },
    appeal() {
      this.$router.push({
        path: "/buy_appeal",
        query: {
          order_id: this.order_id,
          type: this.info.type,
        },
      });
    },
    ossUpload_assistant(data) {
      // f_data,userid,md5,index,total,filetype,type,filename,authorization
      if (data.file.size / 1024 / 1024 > 5) {
        return this.msg("error", this.$t("main.text30"));
      }
      // if (data.action == "cover_compress") {
      //   return this.uploadFile(data.file, 54, 54);
      // } else if (data.action == "cover") {
      //   return this.uploadFile(data.file, 340, 200);
      // }
      let data_obj = data;
      this.coverOrcover_compress = data_obj.action;
      this.file_data = data_obj;

      const bmf = new BMF();
      let _this = this;
      bmf.md5(data_obj.file, function (err, md5) {
        _this.md5_data_fun(md5);
      });
    },
    async md5_data_fun(v) {
      let v_md5 = v;
      if (this.file_data.file) {
        let res = await file_fun(
          this.file_data.file,
          this.userid,
          v_md5,
          1,
          1,
          1,
          100,
          this.file_data.file.name,
          this.token
        );
        let f_url = "";
        if (res.code == 200) {
          f_url = res.data.url;
          this.payment_voucher = f_url;
          this.UPload(f_url);
        } else {
          this.$message.error(res.msg);
        }
      } else {
        this.msg("error", this.$t("main.text31"));
      }
    },
    UPload(url) {
      orders_upload({
        order_id: this.order_id,
        payment_voucher: url,
      }).then((res) => {
        if (res.data.code == 200) {
          this.msg("success", this.$t("main.text10"));
          this.getList();
        } else {
          this.msg("error", res.data.msg);
        }
      });
    },
    async S3Auth() {
      const res = await getFSAuth();
      if (res.data.code == 200) {
        this.token = res.data.data.token;
      } else {
        this.$message.error(res.data.msg);
      }
    },
    getList() {
      orders_get({
        order_id: this.order_id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.info = res.data.data;
          switch (res.data.data.status) {
            case 1:
              this.resetSetItem("hichat_title", this.$t("main.text32"));
              break;
            case 2:
              this.resetSetItem("hichat_title", this.$t("main.text33"));
              break;
            case 3:
              this.resetSetItem("hichat_title", this.$t("main.text34"));
              break;
            case 4:
              this.resetSetItem("hichat_title", this.$t("main.text35"));
              break;
            case 5:
              this.resetSetItem("hichat_title", this.$t("main.text36"));
              break;
            case 6:
              this.resetSetItem("hichat_title", this.$t("main.text37"));
              break;
            case 7:
              this.resetSetItem("hichat_title", this.$t("main.text38"));
              break;
            case 8:
              this.resetSetItem("hichat_title", this.$t("main.text39"));
              break;
            case 9:
              this.resetSetItem("hichat_title", this.$t("main.text40"));
              break;
            case 10:
              this.resetSetItem("hichat_title", this.$t("main.text41"));
              break;
            case 11:
              this.resetSetItem("hichat_title", this.$t("main.text42"));
              break;
          }
        }
      });
    },
    //复制
    copy_api(v) {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.setAttribute("value", v);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.msg("success", this.$t("main.text43"));
    },
    cancel_dlg_confirm() {
      cancel({
        order_id: this.order_id,
      }).then((res) => {
        if (res.data.code == 200) {
          this.cancel_dlg = false;
          this.msg("success", this.$t("main.text10"));
          this.getList();
        } else {
          this.msg("error", res.data.msg);
        }
      });
    },
    subStr(v) {
      if (v) {
        let len = v.length;
        let center = v.substring(4, len - 4);
        return v.replace(center, "****");
      }
    },
  },
};
</script>

<style scoped>
.info_box {
  background: #fff;
  min-height: calc(100vh - 3rem - 2.75rem);
  box-sizing: border-box;
  padding: 0.55rem 0;
}
.info_r1_box {
  border-top: 0.125rem solid #f5f6f7;
}
.info_box .info_r1_box:first-child {
  border: none;
}
.info_r1_box_1 {
  font-size: 0.875rem;
  height: 2.56rem;
  line-height: 2.56rem;
  border-bottom: 0.0625rem solid #f5f6f7;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 0 1.13rem;
}
.info_r1_box .info_r1_box_1:last-child {
  border: none;
}
.info_left {
  /* flex: 1; */
  color: #666;
  padding-right: 1rem;
}
.info_right {
  flex: 1;
  color: #353535;
  text-align: right;
}
.info_id {
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pz_img {
  height: 2.5rem;
}
.pz_box {
  padding: 1rem 0;
  border-bottom: 0.0625rem solid #f5f6f7;
}
.info_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 3rem;
  line-height: 3rem;
  font-size: 1rem;
  color: #fff;
  display: flex;
  width: 100%;
  text-align: center;
}
.info_footer > div {
  flex: 1;
}
.f_btn1 {
  background: #f62727;
}
.f_btn2 {
  background: #2982d8;
}
.v_dlg_main {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}
.f_btn3 {
  background: #f5f6f7;
  color: black;
}
</style>

