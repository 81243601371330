import {
    Promise
} from "core-js";
import {
    up_data_url
} from "../config/endpoints.js";
import store from '../store';

// 上传文件 前缀
// base url  测试服： http://54.151.140.158:39426   正式服：http://file.v2.hichat.chat

function file_fun(
    f_data,
    userid,
    md5,
    index,
    total,
    filetype,
    type,
    filename,
    authorization
) {
    store.state.api_loading = true;

    return new Promise((resolve, reject) => {
        var oMyForm = new FormData();
        // oMyForm.append("data", this.files[0]);
        oMyForm.append("data", f_data);
        var oReq = new XMLHttpRequest();
        // oReq.open("POST", "http://54.151.140.158:39425/api/upload");
        oReq.open("POST", `${up_data_url}/api/upload`);
        // oReq.setRequestHeader('userid', '62c2e241766d3a066ffde08d'); //后台的用户id
        // oReq.setRequestHeader('md5', '4d057e2cd8751dc5cb06144ef3b3a8ad'); //MD5
        // oReq.setRequestHeader('index', 1);
        // oReq.setRequestHeader('total', 1);
        // oReq.setRequestHeader('filetype', 3); // //1图片；2视频；3文件；4语音；
        // oReq.setRequestHeader('type', 100);
        // oReq.setRequestHeader('filename', this.files[0].name);
        oReq.setRequestHeader("userid", userid); //后台的用户id
        oReq.setRequestHeader("md5", md5); //MD5
        oReq.setRequestHeader("index", index);
        oReq.setRequestHeader("total", total);
        oReq.setRequestHeader("filetype", filetype); // //1图片；2视频；3文件；4语音；
        oReq.setRequestHeader("type", type);
        oReq.setRequestHeader("filename", encodeURIComponent(filename));
        //authorization 通过大明接口获取
        // oReq.setRequestHeader('authorization', 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2MmMyZTI0MTc2NmQzYTA2NmZmZGUwOGQiLCJpYXQiOjE2NjUzNzA4MTMsImV4cCI6MTY2NTM3NDQxM30.NKonm8k7JbNmjjUvF8JFGHD3JmArjGB7tdAOfIKPbpA');
        oReq.setRequestHeader("authorization", authorization);
        // 请求成功回调函数

        oReq.onreadystatechange = function () {
            if (oReq.readyState != 4) return store.state.api_loading = false;
            if (oReq.readyState == 4 && oReq.status == 200) {
                // resolve(JSON.parse(oReq.responseText));
                resolve(JSON.parse(oReq.responseText));
                store.state.api_loading = false;
            } else {
                reject("err");
                store.state.api_loading = false;
            }
        };
        oReq.send(oMyForm);
    });
}
export default file_fun;